var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'progress-bar j-progress-bar',
    _vm.size === 'mini' && 'mini',
  ],style:(_vm.styleConfig)},[(_vm.isSingle === true)?_c('div',{key:"single",staticClass:"progress-bar-bg",class:{
      'bg-single-rule': _vm.singleProgress > 0,
      'is-animation': _vm.animation,
      'is-transparent': _vm.transparent,
      'progress-bar-bg__free-shipping': _vm.isFreeShipping,
      'progress-bar-bg__free-shipping_no-transition': _vm.isFreeShipping && !_vm.isTransition,
      'progress-bar-bg__free-shipping_no-border': _vm.isFreeShipping && _vm.singleProgress == 0,
    },style:({
      width: `${_vm.singleProgress}%`,
    })}):(_vm.isSingle === false)?[_c('div',{key:"multiple",staticClass:"progress-bar-bg",class:{
        'bg-multiple-rule': true,
        'is-animation': _vm.animation,
        'is-transparent': _vm.transparent,
      },style:({
        width: `${_vm.multipleProgress}%`,
      })}),_vm._v(" "),(_vm.progressData && !_vm.isSingle)?_vm._l((_vm.progressData),function(item,index){return _c('CircleIcon',{key:index,staticClass:"ico",style:({
          [_vm.GB_cssRight ? 'left' : 'right']: `${100/_vm.progressData.length * (_vm.progressData.length - index - 1)}%`
        }),attrs:{"size":_vm.circleIconSize,"complete":_vm.isComplete(item),"opacity":_vm.isOpacity(item)}})}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }