import { template, plus } from '@shein/common-function'
import { getCouponDiscount, getCouponProgress } from '../../utils/index.js'

export const getProgressDesc = (couponInfo, language) => {
  if (!couponInfo?._data) return {}
  const { activeRule, activeRuleIndex, preRule } = couponInfo._data
  const isFreeShipCoupon = ['5', '6', '9'].includes(couponInfo.apply_for)
  const is_0ThresholdCoupon = activeRuleIndex === 0 && parseFloat(activeRule.min) <= 0 // 判断是否为0门槛券且未选择任何商品

  // const isDiscountFreeShipCoupon = isFreeShipCoupon && couponInfo.apply_for == '9' && couponInfo.shipping_discount_type == 1
  // if (isFreeShipCoupon && !isDiscountFreeShipCoupon) {
  if (isFreeShipCoupon) {
    if (activeRuleIndex == -1) {
      return template(language.SHEIN_KEY_PWA_26130)
    } else {
      return !is_0ThresholdCoupon
        ? template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, language.SHEIN_KEY_PWA_26129)
        : template(language.SHEIN_KEY_PWA_31174)
    }
  }

  if (activeRuleIndex == -1) {
    return template(getCouponDiscount(preRule), language.SHEIN_KEY_PWA_24921)
  } else if (activeRuleIndex == 0) {
    return !is_0ThresholdCoupon
      ? template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`, `<em>${getCouponDiscount(activeRule)}</em>`, language.SHEIN_KEY_PWA_24919)
      : template(getCouponDiscount(activeRule), language.SHEIN_KEY_PWA_31173)
  }

  return template(`<em>${activeRule.need_price?.amountWithSymbol}</em>`,
    `<em>${getCouponDiscount(preRule)}</em>`, 
    `<em>${getCouponDiscount(activeRule)}</em>`, 
    language.SHEIN_KEY_PWA_24920
  )
}

export const getProgressData = (couponInfo) => {
  if (!couponInfo?._data) return []
  const { coupon_rule_list, _data } = couponInfo
  const { activeRuleIndex } = _data
  if (!Array.isArray(coupon_rule_list)) return []

  return coupon_rule_list.map((rule, index) => {
    let progressRate = 0
    if (activeRuleIndex == -1) { // 未找到凑单档位代表全部已满足门槛
      progressRate = 100
    } else if (index == activeRuleIndex) { // 当前凑单档位
      const preRule = coupon_rule_list[index - 1]
      progressRate = getCouponProgress(rule, preRule)
    } else { // 小于当前档位进度为100，反之为0
      progressRate = index < activeRuleIndex ? 100 : 0
    }

    return {
      progressRate, // 凑单进度
      ruleIndex: index, // 当前档位
      activeRuleIndex, // 正在凑单档位
    }
  })
}

export function isCouponProgressUp(cur = [], pre = []) {
  const curProgress = plus(...cur?.map(v => v?.progressRate)) || 0
  const preProgress = plus(...pre?.map(v => v?.progressRate)) || 0
  return curProgress > preProgress
}
