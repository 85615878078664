/**
 * 处理优惠券排序
 * @param {String} primaryCoupon 主券码
 * @param {Array} secondaryCoupon 副券码
 * @param {Boolean} isSorted 是否已排序
 * @param {Array} couponListInfo 优惠券信息
 */
export function handleCouponSort({ primaryCoupon = '', secondaryCoupon = [], isSorted = false, couponListInfo = [] } = {}) {
  if (typeof primaryCoupon !== 'string') return console.error('主券码必须是一个字符')
  if (!primaryCoupon && !secondaryCoupon.length) return console.error('请传入主券码或者副券码')

  // 排序结果
  const sortRes = []

  // 优惠券Mapper
  const couponListMap = new Map(couponListInfo.map(coupon => [coupon.coupon_code, coupon]))

  // 主优惠券信息
  const primaryCouponInfo = couponListMap.get(primaryCoupon)

  // 副优惠券信息
  const secondaryCouponInfo = secondaryCoupon.map(code => couponListMap.get(code)).filter(info => info)

  // 处理副券码排序
  const handleSecondaryCouponSort = (data, isSorted) => {
    // 外部排序,直接返回
    if (isSorted) return data

    // 全部未满足
    const notSatisfied = []
    // 部分满足
    const partiallySatisfied = []
    // 全部满足
    const allSatisfy = []

    data.forEach(coupon => {
      const { is_satisfied } = coupon?._data
      if (is_satisfied === 1) allSatisfy.push(coupon)
      else if (is_satisfied === 0) notSatisfied.push(coupon)
      else if (is_satisfied === 2) partiallySatisfied.push(coupon)
    })

    // 获取当前凑单档位
    const getActiveRule = coupon => coupon?._data.activeRule || coupon?._data.preRule
    // 按差额升序
    const sortByNeedAmount = (a, b) => getActiveRule(a).need_price.amount - getActiveRule(b).need_price.amount
    // 按门槛降序
    const sortByMinAmount = (a, b) => getActiveRule(b).min_amount.amount - getActiveRule(a).min_amount.amount

    // 按全部未满足、部分满足、全部满足进行排序
    // 内部再按特定策略排序
    return [
      ...notSatisfied.sort(sortByNeedAmount),
      ...partiallySatisfied.sort(sortByNeedAmount),
      ...allSatisfy.sort(sortByMinAmount),
    ]
  }

  // 优先主券
  if (primaryCouponInfo) sortRes.push(primaryCouponInfo)
  if (secondaryCouponInfo.length) sortRes.push(...handleSecondaryCouponSort(secondaryCouponInfo, isSorted))

  return sortRes
}

/**
 * 返回最新的优惠券（基于已排序好的优惠券的顺序返回）
 * @param {Array} couponListInfo 当前最新的优惠券(接口返回)
 * @param {Array} prevCouponListInfo 上次接口返回的优惠券
 * @returns
 */
export function handleCouponFilter({ couponListInfo = [], prevCouponListInfo = [] } = {}) {
  const couponListMap = new Map(couponListInfo.map(coupon => [coupon.coupon_code, coupon]))
  return prevCouponListInfo.filter(couponInfo => couponListMap.get(couponInfo.coupon_code)) // 在老数据中，过滤掉新数据中没有的
    .map(m => couponListMap.get(m.coupon_code)) // 替换成新数据
}
